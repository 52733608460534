<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">Bulk update fulfilment</span>
        <button class="delete" aria-label="close" v-on:click="Close"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <p>
          Select the option you want to update and then update with the below
          fields, if you make changes, then select a different field and make
          changes, both changes will be saved when you submit
        </p>
        <br />
        <select
          v-model="selectedField"
          class="select"
          v-on:change="changeEditingField"
        >
          <option :value="null">Select field to update</option>
          <option v-for="o in editableFields" :key="o.name" :value="o">
            {{ o.name }}
          </option>
        </select>
        <br />

        <table class="table" v-if="fieldPath != null">
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Shortcode</th>
            <th>Current Value</th>
            <th>New Value</th>
            <th></th>
          </tr>
          <tr>
            <th colspan="4">Enter here to bulk update</th>
            <th>
              <input
                v-if="selectedField === null || selectedField.type === 'Text'"
                type="text"
                v-model="bulkSetValue"
                v-on:change="updateFieldBulk"
                :disabled="selectedField === null"
              />
              <select
                v-else-if="selectedField.type === 'Select'"
                v-model="bulkSetValue"
                v-on:change="updateFieldBulk"
                :disabled="selectedField === null"
              >
                <option
                  v-for="o in selectedField.options"
                  :value="o.val"
                  :key="o.val"
                >
                  {{ o.text }}
                </option>
              </select>
            </th>
            <th></th>
          </tr>
          <tr v-for="(f, index) in editiblefulfilments" :key="f.Id">
            <td>{{ f.Id }}</td>
            <td>{{ f.Data.billing_contact.billing_contact_email }}</td>
            <td>{{ f.FourLetterCode }}</td>
            <td>{{ GetField(fulfilments[index], true) }}</td>
            <td>
              <input
                v-if="selectedField === null || selectedField.type === 'Text'"
                :disabled="selectedField === null"
                type="text"
                :value="GetField(f)"
                @change="SetField($event.target.value, f)"
              />
              <select
                v-else-if="selectedField.type === 'Select'"
                :disabled="selectedField === null"
                type="text"
                :value="GetField(f)"
                @change="SetField($event.target.value, f)"
              >
                <option
                  v-for="o in selectedField.options"
                  :value="o.val"
                  :key="o.val"
                >
                  {{ o.text }}
                </option>
              </select>
            </td>
            <td>
              <span v-if="getFulfilmentUpdateStatus(f)">
                <i
                  v-if="getFulfilmentUpdateStatus(f).Status === 'Progress'"
                  class="fas fa-spinner fa-spin"
                  title="Update in progress"
                ></i>
                <i
                  v-else-if="getFulfilmentUpdateStatus(f).Status === 'Success'"
                  class="fas fa-check"
                  title="Successfully updated"
                ></i>
                <i
                  v-else-if="getFulfilmentUpdateStatus(f).Status === 'Failed'"
                  class="fas fa-times"
                  :title="'Failed - ' + getFulfilmentUpdateStatus(f).Message"
                ></i>
              </span>
            </td>
          </tr>
        </table>
      </section>
      <footer class="modal-card-foot" v-if="showSave">
        <b-button class="is-success" v-on:click="saveChanges">Save</b-button>
        <button class="button" v-on:click="Close">Cancel</button>
      </footer>
      <footer class="modal-card-foot" v-else>
        <button class="button" v-on:click="Close">Close</button>
        <span>{{ ProgressText }}</span>
      </footer>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";

export default {
  name: "fulfilmentBulkUpdateDialog",
  mixins: [baseMixin],
  props: {
    fulfilments: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      fieldToEdit: ["meta_info", "transaction_ref"],
      editableFields: [
        {
          name: "Is Gift",
          path: ["products_info", "is_gift"],
          type: "Select",
          options: [
            { val: "1", text: "Yes" },
            { val: "0", text: "No" },
          ],
        },
        {
          name: "Fulfilment Code",
          path: ["products_info", "offer_primary_fulfilment_code"],
          type: "Text",
        },
        {
          name: "Secondary Fulfilment Code",
          path: ["products_info", "offer_secondary_fulfilment_code"],
          type: "Text",
        },
        {
          name: "Is Xmas Gift",
          path: ["products_info", "offer_misc", "xmasgift"],
          type: "Select",
          options: [
            { val: "1", text: "Yes" },
            { val: "0", text: "No" },
          ],
        },
      ],
      selectedField: null,
      bulkSetValue: "",
      editiblefulfilments: this.deepCopyFunction(this.fulfilments), //  [...this.fulfilments],
      fulfilmentUpdateStatus: [], //put in the fulfilment id, the status (processing, success,failure, and result text? )
      showSave: true,
    };
  },
  methods: {
    Close() {
      this.$emit("close");
    },
    GetField(f) {
      switch (this.fieldPath.length) {
        case 2:
          return f.Data[this.fieldPath[0]][this.fieldPath[1]];
        case 3:
          return f.Data[this.fieldPath[0]][this.fieldPath[1]][
            this.fieldPath[2]
          ];
      }
    },
    SetField(newValue, f) {
      console.log(newValue);
      switch (this.fieldPath.length) {
        case 2:
          f.Data[this.fieldPath[0]][this.fieldPath[1]] = newValue;
          break;
        case 3:
          f.Data[this.fieldPath[0]][this.fieldPath[1]][
            this.fieldPath[2]
          ] = newValue;
          break;
      }

      this.clearPreviousSave();
    },
    changeEditingField() {
      this.bulkSetValue = "";
      this.clearPreviousSave();
    },
    clearPreviousSave() {
      this.showSave = true;
      this.fulfilmentUpdateStatus = [];
    },
    updateFieldBulk() {
      //get the bulk value
      var that = this;
      //update all other fields (cal set field?)
      this.editiblefulfilments.forEach((f) =>
        that.SetField(that.bulkSetValue, f)
      );
    },
    getFulfilmentUpdateStatus(f) {
      var r = this.fulfilmentUpdateStatus.filter((s) => s.Id === f.Id);
      return r.length > 0 ? r[0] : null;
    },
    saveChanges() {
      var that = this;

      this.editiblefulfilments.forEach(function (f) {
        //Add progress status
        var fulfilStatus = {
          Id: f.Id,
          Status: "progress",
        };
        that.fulfilmentUpdateStatus.push(fulfilStatus);

        //call to save it
        var url = that.APIUrl + "/fulfilment/" + f.Id;
        that.axios
          .post(url, f.Data)
          .then((response) => {
            fulfilStatus.Status = "Success";

            var originalFulfilment = that.fulfilments.filter(
              (o) => o.Id == f.Id
            )[0];
            originalFulfilment.Data = that.deepCopyFunction(f.Data);
          })
          .catch((response) => {
            fulfilStatus.Status = "Failed";
            fulfilStatus.Result = response;
            that.catchError(response);
          });

        //update the fulfilmentUpdateStatus record
      });

      this.showSave = false;
      this.$emit("update");
    },
  },
  computed: {
    fieldPath() {
      return this.selectedField != null ? this.selectedField.path : [];
    },
    ProgressText() {
      var numberToUpdateCount = this.editiblefulfilments.length;
      var successCount = this.fulfilmentUpdateStatus.filter(
        (f) => f.Status === "Success"
      ).length;
      var failedCount = this.fulfilmentUpdateStatus.filter(
        (f) => f.Status === "Failed"
      ).length;
      var inProgressCount = numberToUpdateCount - successCount - failedCount;

      if (successCount === numberToUpdateCount) {
        return "Completed successfully";
      } else {
        var text =
          successCount +
          failedCount +
          " of " +
          numberToUpdateCount +
          " completed";
        if (failedCount > 0) {
          text += " - " + failedCount + " have failed";
        }
        return text;
      }
    },
  },
};
</script>
